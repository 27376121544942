<template>

    <Container :current-name="product" :title="'Product For '+product.toUpperCase()">
        <div class="row pricing">
            <div class="col-xl-4 col-lg-6 col-md-12  col-sm-12" v-for="v in list_product" :key="v.id">
                <div class="card mb-3 bg-blackdrop">
                    <div class="card-body">
                        <h5 class="card-title text-muted text-uppercase text-center">{{v.name}}</h5>
                        <h6 class="card-price text-center">{{v.price | formatNumber}}฿<span class="period">/เดือน</span></h6>
                        <hr>
                        <ul class="fa-ul">
                            <li v-for="can in v.detail_can" :key="can">
                                <span class="fa-li"><i class="fas fa-check"></i></span> {{can}}
                            </li>

                            <li v-for="not in v.detail_not" :key="not" class="text-muted">
                                <span class="fa-li"><i class="fas fa-times"></i></span> {{not}}
                            </li>

                        </ul>
                        <button type="button" @click="BuyProduct(v)" class="btn btn-block btn-primary text-uppercase no-btn bg-fd"> เช่าเลย</button>
                    </div>
                </div>
            </div>
        </div>
    </Container>


</template>

<script>
import Container from '../components/Container'
import ProductService from "@/services/product";
import {mapActions} from "vuex";

export default {
    name:'Products',
    props:['product'],
    data(){
        return {
            list_product:[],
        }
    },
    components:{
        Container
    },
    methods:{
        ...mapActions(['setLoading']),
        async fetchProduct(product){
            await this.setLoading( true)
            try{
                let res = await ProductService.getProductByProduct(product  )
                res.map(x =>{
                    x.detail_can = x.detail_can.split(',').filter(v => v !== "")
                    x.detail_not = x.detail_not.split(',').filter(v => v !== "")
                    return x
                })
                this.list_product = res
                await this.setLoading( false)

            } catch(err){
                await this.setLoading( false)
                let msg = err.message
                let goHome = false
                if (msg === 'not found') {
                    goHome = true
                    msg = 'ไม่พบสินค้านี้'
                }
                await this.$swal.fire('เกิดข้อผิดพลาด',msg,'error')
                if (goHome) await this.$router.push('/home')

            }
        },
        async BuyProduct(product){
            switch (product.buy_mode) {
                case "contact":
                    await this.$swal.fire('แจ้งเตือน','ติดต่อเช่าที่แอดมินเพจ กดที่ OK')
                    window.open(product.buy_contact, "_blank");
                    break;
                case "system":
                    await this.$swal.fire('แจ้งเตือน','ยังไม่รอบรับรูปแบบนี้')
                    break;

            }
        }
    },
    async created() {
        await this.fetchProduct(this.product)
    },
    async beforeRouteUpdate(to,form,next) {
        await this.fetchProduct(to.params.product)
        next()
    }
}
</script>

<style scoped>


.pricing .card {
    border: none;
    border-radius: 1rem;
    transition: all 0.2s;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.pricing hr {
    margin: 1.5rem 0;
}

.pricing .card-title {
    margin: 0.5rem 0;
    font-size: 0.9rem;
    letter-spacing: .1rem;
    font-weight: bold;
}

.pricing .card-price {
    font-size: 1.4rem;
    margin: 0;
}

.pricing .card-price .period {
    font-size: 0.8rem;
}

.pricing ul li {
    margin-bottom: 1rem;
}

.pricing .text-muted {
    opacity: 0.7;
}

.pricing .btn {
    font-size: 80%;
    border-radius: 5rem;
    letter-spacing: .1rem;
    font-weight: bold;
    padding: 1rem;
    opacity: 0.7;
    transition: all 0.2s;
}

/* Hover Effects on Card */

@media (min-width: 992px) {
    .pricing .card:hover {
        margin-top: -.25rem;
        margin-bottom: .25rem;
        box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
    }
    .pricing .card:hover .btn {
        opacity: 1;
    }
}
</style>